import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './home.js';
import About from './about.js';
import Service from './service.js';
import Gallery from './gallery.js';
import BlogVesti from './blog-vesti.js';
import MS from './ms.js';
//import BlogCepa from './blog-cepa.js';
import Contact from './contact.js';
import { Application } from './application.js';
import { Deratizacija, DeratizacijaPests} from './services/deratizacija.js';
import { Dezinsekcija, DezinsekcijaPests } from './services/dezinsekcija.js';
import { Dezinfekcija } from './services/dezinfekcija.js';
import { SuzbijanjeKomaraca, SuzbijanjeKomaracaPests } from './services/suzbijanje-komaraca';
import { SuzbijanjeKorova } from './services/suzbijanje-korova.js';
import { SuzbijanjeKrpelja, SuzbijanjeKrpeljaPests } from './services/suzbijanje-krpelja';
import { Fumigacija, FumigacijaPests} from "./services/fumigacija";
import { ProductList } from "./services/product-list";
//import { Product } from "./product";
import   { Laser  } from './laser.js';
import   { UV  } from './uvDevice.js';
import Notification from "./notification";


// eslint-disable-next-linenpm

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showService: false,
            showLang: false,
            showMenu: false
        };
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    onClickShowMenu = ()=>{
        this.setState(prev => ({showMenu: !prev.showMenu}));
    };

    onClickToogle = ()=>{
        this.setState(prev => ({showService: !prev.showService}));
    };

    onClickDef = ()=>{
        this.setState({showService: false});
        this.setState({showMenu: false});
        this.setState( {showLang: false});

    };

    onClickToogleLang = ()=>{
        this.setState(prev => ({showLang: !prev.showLang}));
    };

    onClickSetLang = (lang) => {
        this.props.setLang(lang);
        this.onClickToogleLang();
    };


    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const l=lang.main;
        return (

            <Router>
                 <React.Fragment>
                        <div className="container clearfix">
                            <header className="clearfix">

                                <Link to="/" className="logo-link display-block one-whole float-left" onClick={this.onClickDef}>
                                    <img className="logo-img display-block one-whole" src={require("../img/logo.svg")} alt="Bio spin logo"/>
                                </Link>
                                <button className="hamburger-button float-right" onClick={this.onClickShowMenu}></button>
                                <Link to="/application" className="aplication-link float-right" onClick={this.onClickDef}>{l.application}</Link>
                                <div className= {"sidenav-wrapper " + (this.state.showMenu? "block" : "")}>
                                    <Link to="/" className="sidenav-item" onClick={this.onClickDef}>{l.home}</Link>
                                    <Link to="/about" className="sidenav-item" onClick={this.onClickDef}>{l.about}</Link>
                                    <a  className="sidenav-item" onClick={ ()=> {
                                        this.setState( {showLang: false});
                                        this.onClickToogle()
                                    } } >{l.service}<i className="fa fa-caret-down"></i></a>
                                    <div className={'subnav-items'+(this.state.showService ? '' : ' hide')}>
                                        <Service lang={this.props.lang} closeServices={this.onClickDef}/>
                                    </div>
                                    <Link to="/gallery" className="sidenav-item" onClick={this.onClickDef}>{l.gallery}</Link>
                                    <Link to="/blog-vesti" className="sidenav-item" onClick={this.onClickDef}>{l.blognews}</Link>
                                    {/*<Link to="/blog-cepa" className="sidenav-item" onClick={this.onClickDef}>{l.cepa}</Link>*/}
                                    <Link to="/ms" className="sidenav-item" onClick={this.onClickDef}>{l.ms}</Link>
                                    <Link to="/contact" className="sidenav-item" onClick={this.onClickDef}>{l.contact}</Link>

                                    <a  className="sidenav-item" onClick={ ()=>{
                                        this.setState({showService: false});

                                        this.onClickToogleLang()
                                    } } >{l.language} <i className="fa fa-caret-down"></i></a>

                                    <div className={'subnav-items'+(this.state.showLang ? '' : ' hide')}>
                                        <div className="container">
                                            <hr className="vertical-line" />
                                            <a  className="sidenav-item" onClick={()=> this.onClickSetLang("rs") } >Srpski </a>
                                            <hr className="vertical-line" />
                                            <a  className="sidenav-item" onClick={()=> this.onClickSetLang("en")} >English </a>
                                            <hr className="vertical-line" />
                                            {/*
                                            <a  className="sidenav-item" onClick={()=> this.onClickSetLang("gr")} >Deutsch </a>
                                            <hr className="vertical-line" />
                                            <a  className="sidenav-item" onClick={()=> this.onClickSetLang("ru")} >Русский </a>
                                            <hr className="vertical-line" />
                                            */}
                                        </div>
                                    </div>
                                </div>

                            </header>

                            <Route exact path="/" component={() => <Home openPopup={this.props.openPopup} lang={this.props.lang} />} />
                            <Route path="/about" component={() => <About openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/gallery" component={() => <Gallery openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route exact path="/blog-vesti" component={() => <BlogVesti openPopup={this.props.openPopup} lang={this.props.lang}/>} />

                            {/*<Route path="/blog-cepa" component={() => <BlogCepa openPopup={this.props.openPopup} lang={this.props.lang}/>} />*/}
                            <Route path="/ms" component={() => <MS openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/contact" component={() => <Contact openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/application" component={() => <Application openPopup={this.props.openPopup} lang={this.props.lang}/>} />

                            <Route path="/deratizacija" component={() => <Deratizacija openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/dezinsekcija" component={() => <Dezinsekcija openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/dezinfekcija" component={() => <Dezinfekcija openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/suzbijanje-komaraca" component={() => <SuzbijanjeKomaraca openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/suzbijanje-korova" component={() => <SuzbijanjeKorova openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/suzbijanje-krpelja" component={() => <SuzbijanjeKrpelja openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/fumigacija" component={() => <Fumigacija openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/product-list" component={() => <ProductList openPopup={this.props.openPopup} lang={this.props.lang}/>} />
                            <Route path="/product/lasers/:id" component={(url) => <Laser openPopup={this.props.openPopup} lang={this.props.lang} id={url.match.params.id}/>} />
                            <Route path="/product/uv/:id" component={(url) => <UV openPopup={this.props.openPopup} lang={this.props.lang} id={url.match.params.id}/>} />

                        </div>

                        <Route path="/deratizacija" component={() => <DeratizacijaPests lang={this.props.lang} />} />
                        <Route path="/dezinsekcija" component={() => <DezinsekcijaPests lang={this.props.lang} />} />
                        <Route path="/suzbijanje-komaraca" component={() => <SuzbijanjeKomaracaPests lang={this.props.lang} /> } />
                        <Route path="/suzbijanje-krpelja" component={() => <SuzbijanjeKrpeljaPests lang={this.props.lang} />} />
                        <Route path="/fumigacija" component={() => <FumigacijaPests lang={this.props.lang} />} />

                     <Notification />
                    </React.Fragment>
            </Router>
        );
    }
}

export default Main
