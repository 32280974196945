import React from 'react';

class Footer extends React.Component {
    render () {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const l=lang.footer;

        return (
            <footer className="clearfix">
                <section className="google-map-location-section">
                    <iframe title="Our location."
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2809.5794226937796!2d19.821331715798063!3d45.23607475665684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b10216e87b58f%3A0x3b1ae2939af896e7!2sBio-Spin!5e0!3m2!1sen!2srs!4v1512763164783"
                            frameBorder="0"  allowFullScreen></iframe>
                    <iframe title="Office location."
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22432.838164458237!2d19.82088515684866!3d45.34669729850138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDXCsDIwJzUwLjMiTiAxOcKwNDknMzMuNyJF!5e0!3m2!1sen!2srs!4v1592210242827!5m2!1sen!2srs"
                            frameBorder="0"  allowFullScreen=""></iframe>
                </section>
                <section className="footer-basic-info-wrapper">
                    <img className="footer-logo" src={require("../img/svg/logo-white.svg")} alt=""/>
                    <p className="footer-description">

                    </p>

                    <p className="footer-call-to-action" onClick={this.props.openPopup}>{l.popupText}</p>

                    <div className="footer-certifications-wrapper clearfix">
                        <div className="certifications-wrapper-1 clearfix">
                            <img className="certification certification-cepa"
                                 src={require("../img/svg/certifications-cepa.svg")}
                                 alt=""/>
                            <img className="certification certification-emca"
                                 src={require("../img/svg/certifications-emca.svg")}
                                 alt=""/>
                        </div>
                        <div className="certifications-wrapper-2 clearfix">
                            <img className="certification certification-pancert"
                                 src={require("../img/svg/certifications-pancert.svg")}
                                 alt=""/>
                            <img className="certification certification-gafta"
                                 src={require("../img/svg/certifications-gafta.svg")} alt=""/>
                            <img className="certification certification-gtas"
                                 src={require("../img/svg/certifications-gtas.svg")} alt=""/>
                        </div>
                    </div>
                </section>
                <section className="footer-contact-info-wrapper">
                    <div className="clearfix">
                        <div className="footer-contact-info empty"></div>
                        <div className="footer-contact-info clearfix">
                            <img className="footer-icon footer-location-info-icon"
                                 src={require("../img/svg/icon-map-pin.svg")}
                                 alt=""/>
                            <p className="footer-info footer-location-info">Laze Lazarevića 18,<br/> 21000 Novi Sad
                            </p>
                        </div>
                        <div className="footer-contact-info clearfix">
                            <img className="footer-icon footer-phone-info-icon"
                                 src={require("../img/svg/icon-smartphone.svg")}
                                 alt=""/>
                            <p className="footer-info">+381 21 6 466 533</p>
                        </div>
                        <div className="footer-contact-info clearfix">
                            <img className="footer-icon footer-fax-info-icon"
                                 src={require("../img/svg/icon-smartphone.svg")} alt=""/>
                            <p className="footer-info">+381 21 6 466 233</p>
                        </div>
                        <div className="footer-contact-info empty"></div>
                    </div>
                    <div className="clearfix">
                        <div className="footer-contact-info empty clear-bottom-border"></div>
                        <div className="footer-contact-info clearfix clear-bottom-border">
                            <img className="footer-icon footer-mail-info-icon" src={require("../img/svg/icon-at.svg")}
                                 alt=""/>
                            <p className="footer-info">biospin@biospin.rs</p>
                        </div>
                        <div className="footer-contact-info clearfix clear-bottom-border">
                            <img className="footer-icon footer-mail-info-icon" src={require("../img/svg/icon-at.svg")}
                                 alt=""/>
                            <p className="footer-info">uprava@biospin.rs</p>
                        </div>
                        <div className="footer-contact-info clearfix clear-bottom-border">
                            <img className="footer-icon footer-website-info-icon"
                                 src={require("../img/svg/icon-website.svg")} alt=""/>
                            <p className="footer-info">www.biospin.rs</p>
                        </div>
                        <div className="footer-contact-info empty clear-bottom-border"></div>
                    </div>
                </section>
            </footer>
        );
    }

}

export default Footer
