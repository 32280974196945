import React from 'react';

const FormErrors = ({formErrors}) => (

        Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0){
                return (
                    <p className='form-error' key={i}>{(fieldName!=="sent")? fieldName+":" : ""} {formErrors[fieldName]}</p>
                )
            } else {
                return '';
            }
        })

);

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            honeypot: "",
            formErrors: {name: "", email: "", message: "", sent: ""},
            nameValid: false,
            emailValid: false,
            messageValid: false,
            formValid: false,
            honeypotValid: true,
            lang: props.lang
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleSubmit(event) {
        let name=this.state.name;
        let email=this.state.email;
        let msg=this.state.message;
        let e=this;
        let lang=require('./langs/'+ this.state.lang +'.json');

        let xmlhttp = new XMLHttpRequest();

        xmlhttp.open("POST", "contact.php",true);
        xmlhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xmlhttp.onreadystatechange = function() {//Call a function when the state changes.
            let msg;
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                let response= this.responseText;
                msg = lang.response[response];
            }
            else {
                msg= lang.response["fail3"];
            }
            e.setState({
                name: "",
                email: "",
                message: "",
                honeypot: "",
                formErrors: {name: "", email: "", message: "", sent: msg },
                nameValid: false,
                emailValid: false,
                messageValid: false,
                formValid: false,
                honeypotValid: true
            });

        };
        xmlhttp.send("name=" + name + "&email=" + email + "&message=" + msg );
        event.preventDefault();
    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value}, () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let messageValid = this.state.messageValid;
        let honeypotValid = this.state.honeypotValid;

        let lang=require('./langs/'+ this.state.lang +'.json');
        let error=lang.assesment.errors;
        switch(fieldName) {
            case 'honeypot':
                honeypotValid = value==="";
                fieldValidationErrors.honeypot = honeypotValid ? '' : error.honeypot;
                break;
            case 'email':
                //eslint-disable-next-line
                emailValid = (value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)===null)?false:true;
                fieldValidationErrors.email = emailValid ? '' : error.email;
                break;
            case 'name':
                nameValid = value.length >= 3;
                fieldValidationErrors.name = nameValid ? '': error.name;
                break;
            case 'message':
                messageValid = value.length>0;
                fieldValidationErrors.message = messageValid ? '': error.message;
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
            emailValid: emailValid,
            nameValid: nameValid,
            messageValid: messageValid,
            honeypot: honeypotValid
        }, this.validateForm);
    }

    validateForm() {

        this.setState({formValid: this.state.honeypot && this.state.nameValid && this.state.emailValid && this.state.messageValid});
    }

    render() {
        const lang = require('./langs/'+ this.props.lang +'.json');
        const traits=lang.traits;
        const c =lang.contact;
        return (
            <React.Fragment>
                <section className="slider-image-section">
                    <div className="slider-text-wrapper">
                        <p className="slider-text">
                            {traits.sliderText}
                        </p>
                        <p className="slider-call-to-action" onClick={this.props.openPopup}>{traits.popupText}</p>
                        <p className="phone-number">{traits.phoneNumber}</p>
                    </div>
                </section>

                <section className="contact-info-section">
                    <div className="contact-info-wrapper">
                        <div className="contact-info">
                            <p className="contact-info-header">{c.head1}</p>
                            <div className="contact-info-body">
                                <p><span className="semi-bold-text">{c.text11}</span>8h - 16h</p>
                                <p><span className="semi-bold-text">{c.text12}</span>{c.text13}</p>
                                <p><span className="semi-bold-text">{c.text14}</span>{c.text13}</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <p className="contact-info-header">{c.head2}</p>
                            <div className="contact-info-body">
                                <p><span className="semi-bold-text">{c.text21}</span>Laze Lazarevića 18, Novi Sad</p>
                                <p><span className="semi-bold-text">{c.text22}</span>021/6466 233; 021/6466 533</p>
                                <p><span className="semi-bold-text">{c.text24}</span>www.biospin.rs | uprava@biospin.rs |
                                    biospin@biospin.rs | info@biospin.rs</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <p className="contact-info-header">{c.head3}</p>
                            <div className="contact-info-body">
                                <p><span className="semi-bold-text">{c.text31}</span>325-9500700030440-55</p>
                                <p><span className="semi-bold-text">{c.text32}</span>330-15009946-76</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <p className="contact-info-header">{c.head4}</p>
                            <div className="contact-info-body">
                                <p><span className="semi-bold-text">{c.text41}</span>100451703</p>
                                <p><span className="semi-bold-text">{c.text42}</span>08281432</p>
                                <p><span className="semi-bold-text">{c.text43}</span>4644</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact-form-section">
                    <p className="contact-form-header">{c.form.head}</p>
                    <div className="form-errors">
                        <FormErrors formErrors={this.state.formErrors} />
                    </div>
                    <form  autoComplete="off" onSubmit={this.handleSubmit} className="clearfix">
                        <div style={{display: "none"}}>
                            <label>Keep this field blank</label>
                            <input type="text" name="honeypot" id="honeypot" value={this.state.honeypot} onChange={this.handleInputChange}/>
                        </div>
                        <div className="contact-form-input-field-wrapper">
                            <input className="contact-form-input-field" placeholder={c.form.name} name="name" type="text" value={this.state.name} onChange={this.handleInputChange}/>
                            <div className="contact-form-input-field-icon-wrapper">
                                <img className="contact-form-input-field-icon"
                                     src={require("../img/svg/icon-user-gray.svg")} alt=""/>
                            </div>
                        </div>
                        <div className="contact-form-input-field-wrapper">
                            <input className="contact-form-input-field" placeholder={c.form.email} name="email" type="email" value={this.state.email} onChange={this.handleInputChange}/>
                            <div className="contact-form-input-field-icon-wrapper">
                                <img className="contact-form-input-field-icon"
                                     src={require("../img/svg/icon-at-gray.svg")} alt=""/>
                            </div>
                        </div>
                        <div className="contact-form-textarea-wrapper">
                            <textarea placeholder={c.form.msg} className="contact-form-textarea" name="message" value={this.state.message} onChange={this.handleInputChange} ></textarea>
                        </div>
                        {/*<input className="contact-form-send-button" disabled={!this.state.formValid} type="submit" value="samo ya validaciju" />*/}


                        <button className={"contact-form-send-button" + (this.state.formValid ? " active": "")}  disabled={!this.state.formValid} type="submit">{c.form.send}</button>

                    </form>
                </section>
            </React.Fragment>
        )
    }
}

export default Contact;
