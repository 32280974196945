import React from 'react';
import { Link } from "react-router-dom";


class Service extends React.Component {
    render () {
        const lang = require('./langs/' + this.props.lang + '.json');
        const s = lang.services;
        return (

            <div className="container">

                <hr className="vertical-line"/>

                <Link to="/deratizacija" className="sidenav-item" onClick={this.props.closeServices}>{s.deratization}</Link>
                <hr className="vertical-line"/>

                <Link to="/dezinsekcija" className="sidenav-item" onClick={this.props.closeServices}>{s.dezinsection}</Link>
                <hr className="vertical-line"/>

                <Link to="/dezinfekcija" className="sidenav-item" onClick={this.props.closeServices}>{s.dezinfection}</Link>
                <hr className="vertical-line"/>

                <Link to="/suzbijanje-komaraca" className="sidenav-item" onClick={this.props.closeServices}>{s.mosquitoControl}</Link>
                <hr className="vertical-line"/>

                <Link to="/suzbijanje-korova" className="sidenav-item" onClick={this.props.closeServices}>{s.weedControl}</Link>
                <hr className="vertical-line"/>

                <Link to="/suzbijanje-krpelja" className="sidenav-item" onClick={this.props.closeServices}>{s.combatingTicks}</Link>
                <hr className="vertical-line"/>

                <Link to="/fumigacija" className="sidenav-item" onClick={this.props.closeServices}>{s.fumigation}</Link>
                <hr className="vertical-line"/>

                <Link to="/product-list" className="sidenav-item" onClick={this.props.closeServices}>{s.products}</Link>
                <hr className="vertical-line"/>
            </div>
        )
    }
}

export default Service;

